import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { routingToRender } from "../utils/routing"
import { navigate } from "@reach/router"

const Wrapper = styled.nav`
  a {
    text-decoration: none;
    font-weight: 300;
    cursor: pointer;
    color: #eaeaea;
    font-size: 1.1rem;
    &.active {
      color: #fff;
      font-weight: 700;
    }
  }
`

const SubMenuWrapper = styled.div`
  margin-left: 20px;
  display: ${p => (p.isOpen ? "flex" : "none")};
  flex-direction: column;
  a {
    font-size: 0.9rem;
  }
`

const SubMenuItem = ({ url, title, currentBrowserHash, onClick }) => {
  const className = currentBrowserHash === url ? "active" : ""
  const clickHandler = e => {
    e.preventDefault()
    onClick()
    navigate(url)
  }
  return (
    <a className={className} onClick={clickHandler}>
      {title}
    </a>
  )
}

const MenuItemWrapper = styled.div``

const MenuItem = ({ children, url, title, onClick }) => {
  return (
    <MenuItemWrapper>
      <Link to={url} onClick={onClick} activeClassName="active">
        {title}
      </Link>
      {children}
    </MenuItemWrapper>
  )
}

const NavMain = ({ className, toggleNav, location }) => {
  const navItemOnClickHandler = () => {
    toggleNav()
  }
  const [currentBrowserPathname, currentBrowserHash] = [
    location.pathname,
    location.hash,
  ]
  return (
    <Wrapper {...{ className }}>
      {routingToRender.map(item => (
        <MenuItem {...{ ...item, onClick: navItemOnClickHandler }}>
          {item.sub && (
            <SubMenuWrapper isOpen={currentBrowserPathname.includes(item.url)}>
              {item.sub.map(item => (
                <SubMenuItem
                  {...{
                    ...item,
                    currentBrowserHash,
                    onClick: navItemOnClickHandler,
                  }}
                />
              ))}
            </SubMenuWrapper>
          )}
        </MenuItem>
      ))}
    </Wrapper>
  )
}

export default NavMain
