import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  position: relative;
`

const Anchore = styled.span`
  position: absolute;
  top: -50px;
  height: 0;
  width: 0;
`

const Group = ({ children, className, id }) => {
  return (
    <Wrapper {...{ className }}>
      <Anchore id={id} />
      {children}
    </Wrapper>
  )
}

export default Group
