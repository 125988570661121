import React from "react"
import styled from 'styled-components';

const Wrapper = styled.div`
  p:nth-child(1){
    font-weight: bold;
  }
`;

const QAItem = ({ id, children }) => {
  return <Wrapper id={id}>{children}</Wrapper>
}

export default QAItem
