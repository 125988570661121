/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import styled, { css } from "styled-components"
import { useStaticQuery, graphql, Link } from "gatsby"

import NavMain from "../components/NavMain"
import Footer from "./Footer"
import { Routing } from "../utils/routing"
import { device } from "../utils/mediaQ"
import { theme } from "../../utils/theme"
import menuIcon from "../images/icon-menu.png"
import "./layout.css"

const navScrollbarStyles = css`
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 6px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #000000;
  }
`

const CLOSED = "__state-closed"
const OPEN = "__state-open"

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;

  #navContainer {
    background-color: ${theme.main};
    color: white;
    display: flex;
    justify-content: flex-end;
    z-index: 3;
    top: 0;
    left: 0;
    .fixed-box {
      ${navScrollbarStyles};
      height: 100vh;
      overflow-y: auto;
      z-index: 1;
    }
    #trigger {
      height: 40px;
      width: 40px;
      position: absolute;
      top: 5px;
      right: 15px;
      background-image: url(${menuIcon});
      background-size: 100% 100%;
    }
  }
  #mainContainer {
    padding: 25px;
    position: relative;
    z-index: 2;
    h1:nth-child(1) {
      color: ${theme.main};
      &:after {
        width: 300px;
        height: 1px;
        content: "";
        display: block;
        background-color: black;
        margin-top: 1rem;
      }
    }
    main {
      max-width: 960px;
    }
    a {
      color: ${theme.main};
      text-decoration: none;
      font-weight: bold;
    }
    .colored-subheader {
      color: ${theme.main};
      font-weight: 800;
    }
  }
  @media screen and ${device.mobileS} {
    grid-template-rows: 50px auto;
    grid-template-columns: 1fr;
    #navContainer {
      position: fixed;
      width: 100%;
      overflow: hidden;
      &.${OPEN} {
        height: 100%;
      }
      &.${CLOSED} {
        height: 50px;
      }
      .fixed-box {
        position: relative;
        padding: 0 25px 25px 25px;
        width: 100%;
      }
      #trigger {
        display: block;
      }
    }
    #mainContainer {
      margin-top: 50px;
    }
  }
  @media screen and ${device.tablet} {
    grid-template-columns: 300px auto;
    grid-template-rows: 1fr;
    #navContainer {
      position: relative;
      grid-template-columns: 300px auto;
      grid-template-rows: 1fr;
      overflow: auto;
      &.${OPEN}, &.${CLOSED} {
        height: 100%;
        min-height: 100vh;
      }
      .fixed-box {
        width: 300px;
        padding: 25px;
        position: fixed;
      }
      #trigger {
        display: none;
      }
    }
    #mainContainer {
      margin-top: 0;
    }
  }
  @media screen and ${device.laptop} {
    grid-template-columns: 400px auto;
    #navContainer {
      .fixed-box {
        width: 400px;
      }
    }
  }
`

// const stylesOpenNavOnMobileDevices = {
//   height: '50px',
// }

const Layout = ({ children, location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const [navStateClassname, setNavState] = useState(CLOSED)

  const toggleNavState = () => {
    if (navStateClassname === OPEN) {
      setNavState(CLOSED)
    } else {
      setNavState(OPEN)
    }
  }

  return (
    <Wrapper>
      <section id="navContainer" className={navStateClassname}>
        <div className="fixed-box">
          <div id="trigger" onClick={toggleNavState} />
          <h1>{data.site.siteMetadata.title}</h1>
          <NavMain
            location={location}
            routing={Routing}
            toggleNav={toggleNavState}
          />
        </div>
      </section>
      <section id="mainContainer">
        <main>
          {children}
          <Footer />
        </main>
      </section>
    </Wrapper>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
