module.exports = {
  getProjectMetadata: function(project = "sportstock") {
    let metadata = {
      title: "SportStock",
      description: "Client Documentation for SportStock platform",
      project,
      url: "https://sportstock.com",
      company: "SportStock Exchange LLC",
      address: "1321 Station Way, Huntington Station, NY 11746",
      supportEmail: "support@SportStock.com",
      urls: {
        video: "https://player.vimeo.com/video/345027457",
      },
    }
    switch (project) {
      case "sportstock": {
        break
      }
      case "inplay": {
        metadata = {
          title: "In-Play",
          description: "Client Documentation for In-Play platform",
          project,
          url: "https://in-play.io",
          company: "In-Play Exchange LLC",
          address: "1321 Station Way, Huntington Station, NY 11746",
          supportEmail: "support@in-play.com",
          urls: {
            video: "https://player.vimeo.com/video/318225198",
          },
        }
        break
      }
      default: {
      }
    }
    return metadata
  },
}
