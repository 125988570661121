import React from "react"
import { getProjectMetadata } from "../../utils/projectMetadataUtil"
import { env } from "../utils/evnVariablesHandlers"

const metadata = getProjectMetadata(env.__PROJECT__)

const ProjectName = ({ url, company, address, support }) => {
  let text = metadata.title
  if (url) text = metadata.url
  if (company) text = metadata.company
  if (address) text = metadata.address
  if (support) text = metadata.supportEmail
  return <span>{text}</span>
}

export default ProjectName
