export const getId = routingConfig => routingConfig.url.replace("#", "")

export const getTitle = routingConfig => routingConfig.title

const routingObjToRenderArray = routingObj => {
  return Object.keys(routingObj).map((key, index) => {
    const item = { ...routingObj[key] }
    if (item.sub) item.sub = routingObjToRenderArray(item.sub)
    return { ...item, key, index }
  })
}

export const Routing = {
  HOME: {
    url: "/",
    title: "How it works",
  },
  RULES:{
    url: "/rules",
    title: "Rules & Scoring",
    sub: {
      NFL:{
        url: "#nfl",
        title: "NFL",
      },
      NBA:{
        url: "#nba",
        title: "NBA",
      },
      NHL:{
        url: "#nhl",
        title: "NHL",
      },
      MLB:{
        url: "#mlb",
        title: "MLB",
      },
    }
  },
  FAQ: {
    url: "/faq",
    title: "FAQ",
    sub: {
      GENERAL_TRADING: {
        url: "#general-trading",
        title: "General Trading",
      },
      PAYMENT_METHODS: {
        url: "#payment-methods",
        title: "Payment Methods",
      },
      ACCOUNT: {
        url: "#account",
        title: "Account",
      },
      ACCOUNT_VERIFICATION: {
        url: "#account-verification",
        title: "Account Verification and Know Your Customer Requirements",
      },
      MISCELLANEOUS: {
        url: "#miscellaneous",
        title: "Miscellaneous",
      },
      ACCOUNT_SUMMARY: {
        url: "#account-summary",
        title: "Account Summary",
      },

      // RESPONSIBLE_GAMBLING: {
      //   url: "#responsible-gambling",
      //   title: "Responsible Gambling",
      // },
    },
  },
  TERMS_OF_USE: {
    url: "/terms-of-use",
    title: "Terms of Use",
    sub: {
      ACCEPTANCE_OF_TERMS: {
        url: "#acceptance-of-terms",
        title: "Acceptance of terms",
      },
      MODIFICATION_OF_TERMS_OF_USE: {
        url: "#modification-of-terms-of-use",
        title: "Modification of Terms of Use",
      },
      ELIGIBILITY: { url: "#eligibility", title: "Eligibility" },
      CONDITIONS_OF_PARTICIPATION: {
        url: "#conditions-of-participation",
        title: "Conditions of participation",
      },
      GAME_RULES: { url: "#game-rules", title: "Game Rules" },
      CONDUCT: { url: "#conduct", title: "Conduct" },
      // USER_CONTENT: { url: "#user-content", title: "User Content" },
      INDEMNITY: { url: "#indemnity", title: "Indemnity" },
      WARRANTY_DISCLAIMERS: {
        url: "#warranty-disclaimers",
        title: "Warranty disclaimers",
      },
      LIMITATION_ON_LIABILITY: {
        url: "#limitation-on-liability",
        title: "Limitation on liability",
      },
      OUR_PROPRIETARY_RIGHTS: {
        url: "#our-proprietary-rights",
        title: "Our proprietary rights",
      },
      LINKS: { url: "#links", title: "Links" },
      // REFUND_POLICY: { url: "#refund-policy", title: "Refund Policy" },
      TERMINATION_AND_SUSPENSION: {
        url: "#termination-and-suspension",
        title: "Termination and suspension",
      },
      NO_THIRD_PARTY_BENEFICIARIES: {
        url: "#no-third-party-beneficiaries",
        title: "No Third-Party beneficiaries",
      },
      NOTICE_AND_PROCEDURE_FOR_MAKING_CLAIMS_OF_COPYRIGHT_INFRINGEMENT: {
        url:
          "#notice-and-procedure-for-making-claims-of-copyright-infringement",
        title:
          "Notice and procedure for making claims of copyright infringement",
      },
      BINDING_ARBITRATION_AND_CLASS_ACTION_WAIVER: {
        url: "#binding-arbitration-and-class-action-waiver",
        title: "Binding arbitration and class action waiver",
      },
      APPLICATION_LICENSE: {
        url: "#application-license",
        title: "APPLICATION LICENSE",
      },
      INTENTIONALLY_LEFT_BLANK: {
        url: "#intentionally-left-blank",
        title: "INTENTIONALLY LEFT BLANK",
      },
      GENERAL_INFORMATION: {
        url: "#general-information",
        title: "GENERAL INFORMATION",
      },
    },
  },
  SECURITY: {
    url: "/security",
    title: "Security Statement",
  },
  PRIVACY_POLICY: {
    url: "/privacy-policy",
    title: "SportStock Exchange LLC - Privacy Policy",
    sub: {
      INTRODUCTION: { url: "#introduction", title: "Introduction" },
      INFORMATION_WE_COLLECT: {
        url: "#information-we-collect",
        title: "Information We Collect.",
      },
      THE_WAY_IN_PLAY_COLLECTS_AND_USES_YOUR_INFORMATION: {
        url: "#the-way-in-play-collects-and-uses-your-information",
        title: "The Way In-Play Collects and Uses Your Information.",
      },
      HOW_IN_PLAY_DISCLOSES_INFORMATION: {
        url: "#how-in-play-discloses-information",
        title: "How In-Play Discloses Information.",
      },
      RETENTION_OF_COLLECTED_INFORMATION: {
        url: "#retention-of-collected-information",
        title: "Retention of Collected Information",
      },
      SAFEGUARD_AND_TRANSFER_OF_YOUR_INFORMATION: {
        url: "#safeguard-and-transfer-of-your-information",
        title: "Safeguard and Transfer of Your Information",
      },
      LEGAL_BASES_FOR_PROCESSING_PERSONAL_DATA: {
        url: "#legal-bases-for-processing-personal-data",
        title: "Legal Bases for Processing Personal Data",
      },
      LEGITIMATE_INTERESTS: {
        url: "#legitimate-interests",
        title: "Legitimate Interests",
      },
      YOUR_CHOICES: { url: "#your-choices", title: "Your Choices" },
      THIRD_PARTY_ADVERTISING_AND_ANALYTICS: {
        url: "#third-party-advertising-and-analytics",
        title: "Third Party Advertising And Analytics",
      },
      INFORMATION_SECURITY: {
        url: "#information-security",
        title: "Information Security",
      },
      YOUR_CALIFORNIA_PRIVACY_RIGHTS: {
        url: "#your-california-privacy-rights",
        title: "Your California Privacy Rights",
      },
      MINORS: { url: "#minors", title: "Minors" },
      CHANGES_AND_UPDATES: {
        url: "#changes-and-updates",
        title: "Changes and Updates",
      },
    },
  },
  RESPONSIBLE_GAMBLING: {
    url: "/responsible-gambling",
    title: "SportStock POLICY ON RESPONSIBLE GAMING",
    sub: {
      RESPONSIBLE_GAMING_TIPS: {
        url: "#responsible-gaming-tips",
        title: "Responsible Gaming Tips",
      },
      HOW_TO_REALIZE_YOU_HAVE_A_PROBLEM: {
        url: "#how-to-realize-you-have-a-problem",
        title: "How to realize you have a problem",
      },
      TAKE_ACTION: { url: "#take-action", title: "Take Action" },
    },
  },
  GLOSSARY: {
    url: "/glossary",
    title: "Trading Glossary",
    sub: {
      A: { url: "#a", title: "A" },
      B: { url: "#b", title: "B" },
      C: { url: "#c", title: "C" },
      D: { url: "#d", title: "D" },
      E: { url: "#e", title: "E" },
      F: { url: "#f", title: "F" },
      H: { url: "#h", title: "H" },
      I: { url: "#i", title: "I" },
      J: { url: "#j", title: "J" },
      M: { url: "#m", title: "M" },
      O: { url: "#o", title: "O" },
      P: { url: "#p", title: "P" },
      S: { url: "#s", title: "S" },
      T: { url: "#t", title: "T" },
      U: { url: "#u", title: "U" },
      V: { url: "#v", title: "V" },
    },
  },
  ODDS_DEFINITIONS: {
    url: "/odds-definitions",
    title: "Odds Definitions",
    sub: {
      DECIMAL_ODDS: { url: "#decimal-odds", title: "Decimal Odds" },
      DECIMAL_TO_FRACTIONAL: {
        url: "#decimal-to-fractional",
        title: "Decimal  to Fractional ",
      },
      DECIMAL_TO_AMERICAN: {
        url: "#decimal-to-american",
        title: "Decimal to American",
      },
      FRACTIONAL_ODDS: { url: "#fractional-odds", title: "Fractional Odds" },
      FRACTIONAL_TO_DECIMAL: {
        url: "#fractional-to-decimal",
        title: "Fractional to Decimal",
      },
      FRACTIONAL_TO_AMERICAN: {
        url: "#fractional-to-american",
        title: "Fractional to American",
      },
      AMERICAN_ODDS: { url: "#american-odds", title: "American Odds" },
      AMERICAN_TO_DECIMAL: {
        url: "#american-to-decimal",
        title: "American to Decimal",
      },
      AMERICAN_TO_FRACTIONAL: {
        url: "#american-to-fractional",
        title: "American to Fractional",
      },
      IMPLIED_ODDS: { url: "#implied-odds", title: "Implied Odds" },
      ODDS_CONVERSION_CHART: {
        url: "#odds-conversion-chart",
        title: "Odds Conversion Chart",
      },
    },
  },
}

export const routingToRender = routingObjToRenderArray(Routing)
