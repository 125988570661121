import React from "react"
import styled from "styled-components"
import { getProjectMetadata } from "../../utils/projectMetadataUtil"
import { env } from "../utils/evnVariablesHandlers"

const Wrapper = styled.footer`
  //text-align: center;
  font-size: 12px;
  border-top: 1px solid black;
  font-weight: bold;
  p {
    margin-bottom: 0;
  }
`

const currentYear = new Date().getFullYear()
const company = getProjectMetadata(env.__PROJECT__).company

const Footer = ({}) => {
  return (
    <Wrapper>
      <p>Please Play Responsibly</p>
      <p>
        Copyright © 2016 - {currentYear} {company}
      </p>
      <p>All rights reserved</p>
    </Wrapper>
  )
}

export default Footer
