import { env } from "../src/utils/evnVariablesHandlers"

const buildTheme = (project = "sportstock") => {
  switch (project) {
    case "sportstock": {
      return {
        main: "#9115b5",
      }
    }
    case "inplay": {
      return {
        main: "#ff6600",
      }
    }
  }
}

export const theme = buildTheme(env.__PROJECT__)
